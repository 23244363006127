import React, { useState } from 'react';
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler
} from 'reactstrap';
import Link from './link'
import Navigation from 'reactstrap-json-nav'
import navigation from '../data/navigation';
import logo from '../assets/images/logo.svg';
import logoAlt from '../assets/images/logo-alt.svg';

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  console.log(props);

  return (
    <Navbar color="light" light={!props.transparent} dark={props.transparent} expand="md">
      <Container>
        <Link to="/" className="navbar-brand">
          {props.transparent ?
            <img src={logo} alt="Logo" style={{height: '8vw', minHeight: '30px', maxHeight: '60px', marginTop: '15px'}} />
          :
            <img src={logoAlt} alt="Logo" height="25px"/>
          }

        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Navigation
            json={navigation}
            link={Link}
            activeClassName="active"
          />
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
