import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import Link from './link';
import styled from "styled-components";
import logo from '../assets/images/logo-footer.svg';

const Footer = styled.footer`
  padding: 3rem 0;
  background: #f8f9fa;
  a, a:hover {
    color: inherit;
  }
  ul {
    color: rgba(0, 0, 0, 0.5);
    -webkit-padding-start: 0;
    padding: 0;
    & > li {
      list-style: none;
      a, a:hover {
        color: inherit;
      }
    }
  }
`

let FooterLink = ({to, children}) => (
  <li>
    <Link to={to}>
      {children}
    </Link>
  </li>
)

export default () => (
  <Footer>
    <Container>
      <Row>
        <Col xs={12} md={4}>
          <img src={logo} alt="Logo" style={{height: '100px'}} />
        </Col>
        <Col xs={12} md={4}>
          <p>KvK: <a href="https://www.kvk.nl/zoeken/?source=all&q=80046843" rel="nofollow noreferrer" target="_blank">80046843</a></p>
        </Col>
        <Col xs={12} md={4} style={{textAlign: 'right'}}>
          <ul>
            <FooterLink to="/">Veelgestelde vragen</FooterLink>
            <FooterLink to="/privacy-verklaring">Privacyverklaring</FooterLink>
          </ul>
        </Col>
      </Row>
    </Container>
  </Footer>
)
